const globalStyles = {
    primaryColor: '#FCD755',
    primaryColorTransparent: 'rgba(252, 215, 85, 0.2)',
    primaryColorTransparent5: 'rgba(252, 215, 85, 0.5)',
    primaryColorDisabled: '#d3d3d3',
    secondaryColor: '#0BAF12',
    secondaryColorTransparent: 'rgba(11, 175, 18, 0.2)',
    tertiaryColor: '#FF0000',
    tertiaryColorTransparent: 'rgba(255, 0, 0, 0.2)',
    colorAmberOrange: '#FFA600',
    colorAmberOrangeTransparent: 'rgba(255, 166, 0, 0.1)',
    colorTangerineOrange: '#FF8531',
    colorTangerineOrangeTransparent: 'rgba(255, 133, 49, 0.1)',
    colorCoralRed: '#FF6361',
    colorCoralRedTransparent: 'rgba(255, 99, 97, 0.1)',
    colorPlumPurple: '#BC5090',
    colorPlumPurpleTransparent: 'rgba(188, 80, 144, 0.1)',
    colorMauvePurple: '#8A508F',
    colorMauvePurpleTransparent: 'rgba(138, 80, 143, 0.1)',
    colorSteelBlue: '#2C4875',
    colorSteelBlueTransparent: 'rgba(44, 72, 117, 0.1)',
    colorDeepTeal: '#003F5C',
    colorDeepTealTransparent: 'rgba(0, 63, 92, 0.1)',
    colorPineGreen: '#1C4E4F',
    colorPineGreenTransparent: 'rgba(28, 78, 79, 0.1)',
    colorDarkGrey: '#333333',
    colorDarkGreyTransparent: 'rgba(51, 51, 51, 0.1)',
    colorLightGrey: '#BDBDBD',
    colorLightGreyTransparent: 'rgba(189, 189, 189, 0.1)',
    colorRed: '#FF3D00',
    colorRedTransparent: 'rgba(255, 61, 0, 0.1)',
    primaryPadding: '16px',
}

export default globalStyles;
